import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';
import { makeStyles } from '@material-ui/styles';
import { 
  AppBar,
  Toolbar,
  Link,
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0px 2px 2px rgba(0,0,0,0.1)'
  }
}));

const Topbar = ({ className, ...rest } : {className: any}) => {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="inherit"
    >
      <Toolbar>
        <Link onClick={() => navigate('/')}>
          <img
            className="testLogo"
            alt="Logo"
            src="./images/headerLogo.png"
            //width="75px" 
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  userProfile: PropTypes.any
};

export default Topbar;
