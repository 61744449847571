import {
  IDashboardState,
  IReportUsage,
} from './dashboard-redux.model';
import { DashboardActionType } from './dashboard-action-types';

export const initialDashboardState: IDashboardState = {
  loadingReportUsage: false,
  loadingReportTimeline: false,
  reportUsage: [],
  reportTimeline: [],
}

const dashboardReducer = (state: IDashboardState = initialDashboardState, action: any) => {
  switch (action.type) {
    case DashboardActionType.GetReportUsage:
      return {
        ...state,
        loadingReportUsage: true,
        reportUsage: []
      };

    case DashboardActionType.GetReportUsageSuccess:
      return {
        ...state,
        loadingReportUsage: false,
        reportUsage: action.payload.data || [],
      };

    case DashboardActionType.GetReportUsageFailure:
      return {
        ...state,
        loadingReportUsage: false,
        reportUsage: [],
      };

    case DashboardActionType.GetReportTimeline:
      return {
        ...state,
        loadingReportTimeline: true,
        reportTimeline: []
      };

    case DashboardActionType.GetReportTimelineSuccess:

      return {
        ...state,
        loadingReportTimeline: false,
        reportTimeline: action.payload.data || [],
      };

    case DashboardActionType.GetReportTimelineFailure:
      return {
        ...state,
        loadingReportTimeline: false,
        reportTimeline: [],
      };

    default:
      return state
  }
}

export default dashboardReducer;