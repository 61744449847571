import {
  APIURL,
  client,
  atAPIOptions,
} from './at-app-api';

export const atGetUserInfo = () => {
  const request = client();
  return request.get(
      `${APIURL}/api/userinfo/admin`
  );
};

export const atSignAgreement = (data: any) => {

  const request = client();
  return request.post(
    `${APIURL}/api/userinfo/sign`,
    data,
  );
};

