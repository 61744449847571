import { createTheme } from '@material-ui/core/styles';
import palette from './palette';
import { typography } from './typography';
import overrides from './overrides';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';

const baseTheme : ThemeOptions = {
  palette,
  typography,
  overrides
};

export const theme = createTheme(baseTheme);
