import React, { useState, useEffect, useMemo, useCallback, useContext} from 'react';
import { navigate } from 'hookrouter';

import {
	get as _get,
  keys as _keys,
  orderBy as _orderBy,
} from 'lodash';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import Page from '../../../components/page';
import gradients from '../../../utils/gradients';

import {
  Navigations
} from '../../../settings/nav-config';
import { IAuthenticationErrors } from '../../../store/auth/auth-redux.model';
import { AuthActionType } from '../../../store/auth/auth-action-types';
import { AdminVersion } from '../../../settings/app';

const useStyles: any = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  subtitle: {
    paddingBottom: theme.spacing(2)
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  loginForm: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  versionText: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    color: "rgba(0,0,0,0.5)",
    height: "50px",
    textAlign: "center",
    fontSize: "10px"
  }
}));

const AutoLoginContainer = (props: any) => {
  const classes = useStyles();

	useEffect(() => {
  }, []);

	return (
    <Page
      className={classes.root}
      title="Logging In..."
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <LockIcon className={classes.icon} />

          <Typography
            gutterBottom
            variant="h3"
          >
            Sign in
          </Typography>

          <Typography paragraph variant="subtitle2">
            Welcome to the ARWell PRO Therapist Portal!
          </Typography>

          <Typography paragraph variant="subtitle2">
            Signing you in... please wait...
          </Typography>

        </CardContent>

        <CardMedia
          className={classes.media}
          image="./images/auth.png"
          title="Cover"
        >
        </CardMedia>

        
      </Card>
      <div className={classes.versionText}>
      <p>v{AdminVersion} | Augment Therapy, Inc. </p>
      </div>
    </Page>
	);
};

export default AutoLoginContainer;
