import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { navigate } from "hookrouter";
import { toast } from "react-toastify";
import { get as _get } from "lodash";

import { UserActionType } from "./user-action-types";

import { DefaultRoute, Navigations } from "../../settings/nav-config";
import { getAtError } from "../../settings/error";
import { UserRoles } from "../../settings/user-roles";
import { atGetUserInfo, atSignAgreement } from "../../services/at-app-user-api";

function* getUserInfo(action: any) {
  try {
    const data = yield call(atGetUserInfo);
    yield put({ type: UserActionType.GetUserInfoSuccess, payload: data });
    const role = _get(data, ["data", "role"]);
    const uid = _get(data, ["data", "uid"]);

    // save this info for use in therapist logins
    const qr = _get(data, ["data", "qr"]);
    localStorage.setItem("qrSaved",qr);

    if (role && uid) {
      if (role === UserRoles.ENDUSER) {
        navigate(`users/patients/${uid}`);
      } else if (role === UserRoles.USER){
        navigate(`users/therapists/${uid}`)
      } else {
        navigate(DefaultRoute);
      }
    } else {
      navigate(DefaultRoute);
    }
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: UserActionType.GetUserInfoFailure, message: error });
  }
}

function* signAgreement(action: any) {
  try {
    const data = yield call(
      atSignAgreement,
      action.payload.data
    );

    if (data && data.data)
      yield put({ type: UserActionType.PostSignAgreementSuccess, payload:{data: action.payload.data}});
    else {
      toast.error(`Failed`);
      yield put({
        type: UserActionType.PostSignAgreementFailure,
        message: 'Failed',
      });
    }

  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({
      type: UserActionType.PostSignAgreementFailure,
      message: error,
    });
  }
}

const userSagas = [
  takeEvery(UserActionType.GetUserInfo, getUserInfo),
  takeEvery(UserActionType.PostSignAgreement, signAgreement),
];

export { userSagas };
