import {
  get as _get,
  differenceBy as _differenceBy
} from 'lodash';
import { IUserState } from './user-redux.model';
import { UserActionType } from './user-action-types';

export const initialUserState: IUserState = {
  customer: {
    customerName: '',
    uid: '',
  },
  profile: {
    id: 0,
    uid: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    optInMethod: 'None',
    endUserTotal: 0,
    facilities: [],
    categories: [],
    protocols: [],
    unreadAgreements: []
  },
  signAgreementRequest: false,
  userInfoRequest: false,
}


const userReducer = (state: IUserState = initialUserState, action: any) => {
  switch (action.type) {
    case UserActionType.GetUserInfo:
      return {
        ...state,
        profile: null,
        userInfoRequest: true,
      };
    case UserActionType.GetUserInfoSuccess:
      return {
        ...state,
        profile: action.payload.data || null,
        userInfoRequest: false,
      };
    case UserActionType.GetUserInfoFailure:
      return {
        ...state,
        profile: null,
        userInfoRequest: false,
      };

    case UserActionType.PostSignAgreement:
      return {
        ...state,
        signAgreementRequest: true,
      };
    case UserActionType.PostSignAgreementSuccess:
      let readAgreementUids = (action.payload.data || []).map((a: any) => ({uid: a.agreementUid}));
      let unreadAgreements = _get(state.profile, ['unreadAgreements']) || [];
      unreadAgreements = _differenceBy(unreadAgreements, readAgreementUids, 'uid');

      return {
        ...state,
        profile: {
          ...(state.profile || {}),
          unreadAgreements
        },
        signAgreementRequest: false,
      };
    case UserActionType.PostSignAgreementFailure:
      return {
        ...state,
        signAgreementRequest: false,
      };
    default:
      return state
  }
}

export default userReducer;
