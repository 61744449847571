import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Theme, Card, CardContent, Typography, } from '@material-ui/core';

import Page from '../../components/page';
import { ATtelehealthinstall } from '../../settings/app';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  videoPlayer: {
    maxWidth: 400,
    height: '280px',
    padding: '10px'
  },
  resourceCard: {
    margin: '15px', 
    display: 'inline-grid',
    minWidth: '450px',
    height: '340px'
  },
  videoBox: {
    display: 'inline-box',
    paddingBottom: '20px',
  },
  resourceLink: {
    padding: '20px',
    textAlign: 'center'
  },
  resourceTitle: {
    fontSize: '14px',
    paddingBottom: '10px',
    color: '#546e7a'
  },
  textBox: {
    margin: '10px',
    fontSize: '12px',
  }
}));

const openLink = (strLink: string) => {
  window.open( strLink, "_blank"); 
}

const TelehealthDefault = (props: any) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Telehealth"
    >

    <Card className={classes.resourceCard}>
      <CardContent>
        <div className={classes.resourceTitle}>
          Using Telehealth
        </div>
        
        <p className={classes.textBox}>Telehealth is now available as a downloaded application for Windows!</p><p className={classes.textBox}>Click the button below to install the latest version of the Telehealth app.</p>
        <div className={classes.resourceLink}>
          <button
              title="downloadtelehealth"
              className="k-button k-primary"
              onClick={() => openLink(ATtelehealthinstall)}
            >
            Download Telehealth App
          </button>
      </div>
      </CardContent>
    </Card>

    </Page>
  );
}

export default TelehealthDefault;
