import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
  CircularProgress,
  Grid,
  Typography,
  Button,
  colors,
  Theme,
  Card,
  CardContent
} from '@material-ui/core';

import {
  find as _find,
  filter as _filter,
  get as _get,
  keys as _keys,
  capitalize as _capitalize,
  trimEnd as _trimEnd,
  values,
  valuesIn,
} from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';


import CircularIndeterminate from '../../../../components/circular-indeterminate';
import AtTextField from '../../../../components/form/text-field';
import AtAutocomplete from '../../../../components/form/autocomplete';
import { TimeZoneOptions } from '../../../../settings/app';
import { MonthSelection } from '@material-ui/pickers/views/Month/MonthView';
import session from 'redux-persist/lib/storage/session';
import { type } from 'os';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FacilityActionType } from '../../../../store/facilities/facilities-action-types';
import { UserRoles } from '../../../../settings/user-roles';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import App from '../../../../app';
import { toast } from 'react-toastify';
//import Picker from 'react-month-picker';



const yup = require('yup');

const ExportDataFormSchema = yup.object().shape({
  clinics: yup.string(),
  sessionType: yup.string(),
  app: yup.string(),
  startdate: yup.string(),
  enddate: yup.string(),
});

const appOptions = [
  { label: "Augment Therapy", value: "1"},
  { label: "Augment Adventures", value: "2"},
  { label: "Augment Exercise", value: "3"}
];

const sessionOptions = [
  { label: "With a Provider", value: "1"},
  { label: "Solo Sessions", value: "2"},
  { label: "Telehealth Sessions", value: "3"}
];



const useStyles = makeStyles((theme: Theme) => ({
    card: {
      maxWidth: '100%',
    },
    cardContent: {
      padding: theme.spacing(2, 4),
    },
    form: {
      position: 'relative',
    },
    formHeader: {
        width: '100%',
        padding: theme.spacing(1),
        color: "#2962FE",
        fontSize: '20px'
      },
    formControl: {
      width: '100%',
      padding: theme.spacing(1),
      minHeight: 86,
      },
      helperText: {
          padding: '0 10px',
    },
    buttonContainer: {
      textAlign: 'right',
    },
    submitButton: {
      marginTop: theme.spacing(2),
      width: 100,
      padding: theme.spacing(1),
    },
    msg: {
      fontSize: "12px",
      padding: theme.spacing(2),
    }
  }));

const ExportDataForm = (props: any) => {
  const {
    loading,
    handleCancel,
    userProfile,
    clinicOptions,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [app, setApp ] = useState([]);
  const [sessionType, setSessionType] = useState([]);
  const [clinics, setClinics] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const changeClinics = (values: any) => {
    setClinics(values);
  }

  const sessionChange = (values: any) => {
    setSessionType(values);
  };

  const appChange = (values: any) => {
    setApp(values);
  };

  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    validationSchema: ExportDataFormSchema,
  });
  const { register, errors, reset, formState, getValues, setValue, watch, triggerValidation, handleSubmit } = hookFormMethods;

  const isLoadingExport = useSelector(
    (state) => _get(state, ['facilities', 'loadingDataReport']) || false,
    shallowEqual
  )

  useEffect(() => {
    if (!isLoadingExport && isLoading) {
      setIsLoading(false);
      toast.success("Your data has been exported successfully!");
    }
    return () => {}
  }, [isLoadingExport])

  const handleSave = (data: any) => {

    if (!formState.isValid)
      return;

    const formValues: any = _cloneDeep(data);

    // put in other params here
    if (clinics.length) {
      formValues.facility = clinics[0]['value'];
    }
    if (sessionType.length) {
      formValues.sessionType = sessionType[0]['value'];
    }
    if (app.length) {
      formValues.appType = app[0]['value'];
    }
    
    setIsLoading(true);

    if([ UserRoles.SUPERUSER, UserRoles.COMPANYADMIN].indexOf(userProfile.role) >= 0){
      dispatch({ type: FacilityActionType.GetDataExport, payload: formValues});
    }

    // TODO: recognize the success of the export properly
    // setTimeout(function() {
    //   setIsLoading(false);
    // }, 11000);
  }

  return (
    <>
    
    <Card className={classes.card}>
    <CardContent>
    <div className={classes.formHeader}>New Data Export</div>
    {
        isLoading && (
          <CircularIndeterminate/>
        )
      }
    <form
      data-testid="export-data-form"
      className={classes.form}
      onSubmit={handleSubmit(handleSave)}>
      
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}>
        <Grid item xs={12} sm={6}>
        
        {
        isLoading && (
          <div className={classes.msg}>Please be patient while your data export is prepared...</div>
        )
      }
        </Grid>
        <Grid
          item xs={12}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.submitButton}
              color="primary"
              size="medium"
              variant="text"
              disabled={loading}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              className={classes.submitButton}
              color="secondary"
              size="medium"
              variant="contained"
              disabled={isLoading}
              type="submit"
            >
            Download
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
    </CardContent>
    </Card>
    </>
  );
}

export default ExportDataForm;
