import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import { navigate, usePath, useRoutes, useRedirect, setBasepath } from 'hookrouter';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import {
  find as _find,
  get as _get
} from 'lodash';

import '@progress/kendo-theme-material/dist/all.css';
import 'react-toastify/dist/ReactToastify.css';

import './app.scss';
import StylesProvider from './components/styles-provider';
import { theme } from './theme';

import './assets/scss/main.scss';
import Routes from './routes';
import NotFound from './pages/not-found';
import {
  get_browser
} from './utils/browser';
import {
  unsupportedBowsers
} from './settings/browser';
import { BrowserRouter } from 'react-router-dom';
import { basename } from 'path';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const App = (props: any) => {
  const routeResult = useRoutes(Routes);
  const browser = get_browser();
  

  if (unsupportedBowsers.find(b => b.name === browser.name && b.version >= parseInt(browser.version))) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 300,
        height: '100%',
        fontSize: 18
      }}>
        We don't currently support this browser.
      </div>
    )
  } else {
    return (
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <StylesProvider direction='ltr'>
              {routeResult || <NotFound/>}
              <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_RIGHT}/>
            </StylesProvider>
          </ThemeProvider>
        </MuiThemeProvider>
    );
  }
}


export const AppTest = (props: any) => {
  const {
    route
  } = props;

  const routeResult = useRoutes(Routes);

  useEffect(() => {
    if (route)
      navigate(route);
  }, [route])

  return (
    routeResult || <NotFound/>
  );
}

export default App;
