/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { navigate, usePath } from 'hookrouter';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  get as _get,
  startsWith as _startsWith,
} from 'lodash';

import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Link,
  colors,
  Theme
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';

import NavItem from '../../components/nav-item';
import NavConfig, { Navigations } from '../../settings/nav-config';
import { AuthActionType } from '../../store/auth/auth-action-types';
import { FacilityActionType } from '../../store/facilities/facilities-action-types';
import { AdminVersion, ATtherapistRemoteURL, RUN_MODE } from '../../settings/app';

const useStyles = makeStyles((theme : Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 170, //256,
  },
  desktopDrawer: {
    width: 170, //256,
    top: 66,
    height: 'calc(100% - 66px)',
    paddingTop: 20
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2),
    fontSize: '8px'
  },
  devenv: {
    marginLeft: theme.spacing(2),
    fontSize: '8px',
    color: 'red',
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  active: {
    fontSize: '12px'
  }
}));

const renderNavItems = (props: any) => {
  const {
    userProfile,
    dispatch,
    items,
    subheader,
    key,
    ...rest
  } = props;

  return (
    <List key={key}>
      {
        subheader && (
          <ListSubheader disableSticky>{subheader}</ListSubheader>
        )
      }
      {/* eslint-disable-next-line react/prop-types */}
      {
        items.reduce(
          // eslint-disable-next-line no-use-before-define
          (acc: any, item: any) => reduceChildRoutes({ acc, userProfile, dispatch, item, ...rest }),
          []
        )
      }
    </List>
  );
}

const reduceChildRoutes = (props: any) => {
  const {
    acc,
    userProfile,
    item,
    path,
    depth,
    dispatch,
    onMobileClose,
  } = props;

  const open = _startsWith(path, item.href);

  if (item.roles.indexOf(userProfile.role) >= 0) {
    acc.push(
      <NavItem
        depth={depth || 0}
        icon={item.icon}
        key={item.href + item.title}
        href={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
        onClick={(e) => {
          if (item.onClick || item.href) {
            onMobileClose();
          }

          if (item.title === 'Sign out') {
            dispatch({type: AuthActionType.Logout, payload: null});
          } else if (item.title === 'Therapist') {
            navigate(`${Navigations.users.root}/${Navigations.users.therapists}/${userProfile.uid}`);
            dispatch({type: FacilityActionType.SetActiveFacility, payload: { activeTherapist: userProfile, activePatient: null }});
          } else if (item.title === 'Admins') {
            navigate(`${Navigations.users.root}/${Navigations.users.staff}/${userProfile.uid}`);
            dispatch({type: FacilityActionType.SetActiveFacility, payload: { activeStaff: userProfile, activePatient: null }});
          }

          if (item.onClick) {
            dispatch({type: FacilityActionType.SetActiveFacility, payload: { activeHospital: null, activeClinic: null, activeTherapist: null, activePatient: null, activeStaff: null }});
            item.onClick(e);
          }
        }}

      >
        {
          item.items ? (
            <>
              {
                renderNavItems({
                  depth: (depth || 0) + 1,
                  path,
                  userProfile,
                  dispatch,
                  onMobileClose,
                  items: item.items
                })
              }
            </>
          ) : null
        }
      </NavItem>
    );
  }

  return acc;
}

const NavBar = (props: any) => {
  const {
    openMobile,
    onMobileClose,
    className,
    userProfile,
  } = props;

  const classes = useStyles();
  const path = usePath();
  const dispatch = useDispatch();
  // const session = useSelector((state: any) => state.session);
  const [status, setStatus] = useState('online');

  const handleStatusToggle = () => {
    const statusSeq: any = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online'
    };
    setStatus(statusSeq[status]);
  };

  const startTelehealth = () => {
    window.open( ATtherapistRemoteURL, "_blank"); 
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [path]);

  const content = (
    <div
      className={clsx(classes.root, className)}
    >
      <nav className={classes.navigation}>
        {
          NavConfig.map((list: any) => renderNavItems({
            dispatch: dispatch,
            onMobileClose: onMobileClose,
            userProfile: userProfile,
            items: list.items,
            subheader: list.subheader,
            key: list.subheader,
            path: path,
          }))
        }
      </nav>
      <Divider />
      <div className={classes.profile}>
        <div>
          <br />
        </div>
        <div className={classes.details}>
            v{AdminVersion} | Augment Therapy, Inc.  
            {RUN_MODE==='testing' && (
          <p className={classes.devenv}>
          *** TESTING ENV ***  
          </p>
        )}
        </div>
        
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  userProfile: PropTypes.any,
};

export default NavBar;
