import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Theme, Card, CardContent, Typography, } from '@material-ui/core';

import Page from '../../components/page';
import YouTube from 'react-youtube';
import { ATexerciseGuide, ATsupportSite, ATtelehealthinstall, ATuserguide } from '../../settings/app';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  videoPlayer: {
    maxWidth: 400,
    height: '280px',
    padding: '10px'
  },
  resourceCard: {
    margin: '15px', 
    display: 'inline-grid' ,
    minWidth: '450px',
    height: '340px'
  },
  resourceCardFull: {
    margin: '15px', 
    display: 'inline-grid' ,
    padding: '20px',
    minWidth: '90%',
  },
  videoBox: {
    display: 'inline-box',
    paddingBottom: '20px',
  },
  resourceLink: {
    padding: '20px',
    textAlign: 'center'
  },
  resourceTitle: {
    fontSize: '14px',
    paddingBottom: '10px',
    color: '#546e7a'
  },
  resourceCert: {
    padding: '10px',
    alignContent: 'middle'
  },
  spacerBox: {
    minWidth: '30px',
    display: 'inline-grid' ,
    padding: '20px',
  }
}));

const openLink = (strLink: string) => {
  window.open( strLink, "_blank"); 
}

const ResourcesDefault = (props: any) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Resources"
    >

  <Card className={classes.resourceCardFull}>
      <CardContent>
        <div className={classes.resourceTitle}>
          Getting Started
        </div>
        <div className={classes.resourceLink}>
          <button
              title="support"
              className="k-button k-primary"
              onClick={() => openLink(ATsupportSite)}
            >
            Provider Support Home
          </button>
          <div className={classes.spacerBox}>&nbsp;</div>
          <button
              title="userguide"
              className="k-button k-primary"
              onClick={() => openLink(ATuserguide)}
            >
            User Guide
          </button>
          <div className={classes.spacerBox}>&nbsp;</div>
          <button
              title="exerciseguide"
              className="k-button k-primary"
              onClick={() => openLink(ATexerciseGuide)}
            >
            Exercise Guide
          </button>
          {/* <div className={classes.spacerBox}>&nbsp;</div>
          <button
              title="downloadtelehealth"
              className="k-button k-primary"
              onClick={() => openLink(ATtelehealthinstall)}
            >
            Download Telehealth App
          </button> */}
        </div>
      </CardContent>
    </Card>

    <Card className={classes.resourceCard}>
      <CardContent>
        <div className={classes.resourceTitle}>
        Welcome: Patients
        </div>
        <div className={classes.videoBox}>
          <YouTube 
          id="video-patients"
          videoId="-FAJk-IOBzM"
          className={classes.videoPlayer}/>
        </div>
      </CardContent>
    </Card>

    <Card className={classes.resourceCard}>
      <CardContent>
        <div className={classes.resourceTitle}>
        Welcome: Therapists
        </div>
        <div className={classes.videoBox}>
          <YouTube 
          id="video-therapist"
          videoId="dQLSV1dJqPI"
          className={classes.videoPlayer}/>
        </div>
      </CardContent>
  </Card>


  <Card className={classes.resourceCard}>
    <CardContent>
      <div className={classes.resourceTitle}>
        Brain Break Activity
      </div>        
      <div className={classes.videoBox}>
        <YouTube 
        id="video-breakactivity"
        videoId="G2QQ5MZm6jc"
        className={classes.videoPlayer}/>
      </div>
      
    </CardContent>
  </Card>

  

  <Card className={classes.resourceCard}>
    <CardContent>
    <div className={classes.resourceTitle}>
        Printable Certificates
      </div>
      <div className={classes.resourceCert}>
        <a href="./assets/print/exercise_excellence.pdf" target="blank"><img src="./assets/img/exercise_excellence.png" alt="Exercise Excellence" height={125}></img></a>
        <a href="./assets/print/grand_explorer.pdf" target="blank"><img src="./assets/img/grand_explorer.png" alt="Grand Explorer" height={125}></img></a>
        <a href="./assets/print/great_persistence.pdf" target="blank"><img src="./assets/img/great_persistence.png" alt="Great Persistence" height={125}></img></a>
      </div>
      <div className={classes.resourceCert}>
        <a href="./assets/print/record_breaker.pdf" target="blank"><img src="./assets/img/record_breaker.png" alt="Record Breaker" height={125}></img></a>
        <a href="./assets/print/you_did_starrific.pdf" target="blank"><img src="./assets/img/you_did_starrific.png" alt="Starrific" height={125}></img></a>
        <a href="./assets/print/youre_on_fire.pdf" target="blank"><img src="./assets/img/youre_on_fire.png" alt="On Fire" height={125}></img></a>
        </div>
    </CardContent>
  </Card>

  </Page>
  );
}

export default ResourcesDefault;
