export const getActiveFacilityPayload = (subType: any, uid: string) => {
  const payload: any = {};

  if (['hospitals'].indexOf(subType) >= 0) {
    payload.activeClinic = null;
    payload.activeTherapist = null;
    payload.activePatient = null;
    payload.activeSession = null;
    if (!uid)
      payload.activeHospital = null;
  } else if (['clinics'].indexOf(subType) >= 0) {
    payload.activeTherapist = null;
    payload.activePatient = null;
    payload.activeSession = null;
    if (!uid)
      payload.activeClinic = null;
  } else if (['therapists'].indexOf(subType) >= 0) {
    payload.activePatient = null;
    payload.activeSession = null;
    if (!uid)
      payload.activeTherapist = null;
  } else if (['patients'].indexOf(subType) >= 0) {
    payload.activeSession = null;
    if (!uid)
      payload.activePatient = null;
  }

  return payload;
}