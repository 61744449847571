import { all } from 'redux-saga/effects';

import { authSagas } from './auth/auth.saga';
import { userSagas } from './user/user.saga';
import { dashboardSagas } from './dashboard/dashboard.saga';
import { facilitiesSagas } from './facilities/facilities.saga';


export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...userSagas,
    ...dashboardSagas,
    ...facilitiesSagas
  ])
}