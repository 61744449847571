import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { usePath, useRoutes, useRedirect } from "hookrouter";
import { navigate } from "hookrouter";
import { get as _get } from "lodash";
import { UserRoles } from './settings/user-roles';

const ProtectedRoute = (props: any) => {
  const { component } = props;

  const path = usePath();
  const authentication = useSelector(
    (state) => _get(state, ["auth", "authentication"]) || null
  );
  const userProfile = useSelector(
    (state) => _get(state, ["user", "profile"]) || null,
    shallowEqual
  );

  const handleLogin = () => {
    navigate(`/login`);
  };

  useEffect(() => {
    if (!authentication || !userProfile) {
      handleLogin();
    } else if (_get(userProfile, ['unreadAgreements', 'length'])) {
      navigate(`/agreement`);
    }
  }, [authentication, userProfile, path]);

  if (!authentication || !userProfile || _get(userProfile, ['role']) === UserRoles.USER && _get(userProfile, ['unreadAgreements', 'length']))
    return null;

  return component;
};

export default ProtectedRoute;
