import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Theme, Card, CardContent, Typography, } from '@material-ui/core';

import {
    find as _find,
    filter as _filter,
    get as _get,
    keys as _keys,
    capitalize as _capitalize,
    cloneDeep as _cloneDeep,
    trimEnd as _trimEnd,
    size,
  } from "lodash";

import Page from '../../components/page';
import YouTube from 'react-youtube';
import ExportDataForm from '../facilities/components/forms/export-data-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FacilityActionType } from '../../store/facilities/facilities-action-types';
import { Picker } from '@material-ui/pickers';
import { type } from 'os';
import { Navigations } from '../../settings/nav-config';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  videoPlayer: {
    maxWidth: 400,
    height: '280px',
    padding: '10px'
  },
  resourceCard: {
    margin: '15px', 
    display: 'inline-grid' ,
    minWidth: '450px',
    height: '340px'
  },
  videoBox: {
    display: 'inline-box',
    paddingBottom: '20px',
  },
  resourceLink: {
    padding: '20px',
    textAlign: 'center'
  },
  resourceTitle: {
    fontSize: '14px',
    paddingBottom: '10px',
    color: '#546e7a'
  },
  resourceCert: {
    padding: '10px',
    alignContent: 'middle'
  }
}));

const ExportDataDefault = (props: any) => {
  const {
    type,
  } = props;

const dispatch = useDispatch();
const [clinicOptions, setClinicOptions] = useState([] as any);

const clinics = useSelector(
    (state) => _get(state, ["facilities", "clinics"]) || { data: [], total: 0 },
    shallowEqual
  );
  const userProfile = useSelector(
    (state) => _get(state, ["user", "profile"]) || null,
    shallowEqual
  );

const handleCancel = () => {
    window.history.back();
  };

const loading = false; //needs to be implemented

  useEffect(() => {

    dispatch({
      type: FacilityActionType.GetFacilities,
      payload: {
        type: type,
        subType: Navigations.facilities.clinics,
        targetType: Navigations.facilities.clinics,
        facilityUid: null,
        parentFacilityUid: null,
        parentUid: null,
        skip: 0,
        take: 0
      }
    });

    return () => {
    };
  }, []);


  useEffect(() => {

    const temp: any = [];
    const filteredData = clinics.data;
    for (const item of filteredData) {
      temp.push({ value: item.uid, label: item.name });
    }
    setClinicOptions(temp);

    return () => {};
  }, [clinics]);
  

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Export Data"
    >

    <ExportDataForm
    {...{
        loading,
        handleCancel,
        userProfile, 
      clinicOptions}
    }/>

  </Page>
  );
}

export default ExportDataDefault;

