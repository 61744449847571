import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navigate, useQueryParams } from "hookrouter";
import { get as _get } from "lodash";
import { AuthActionType } from "./store/auth/auth-action-types";

const ProvideConsentRoute = (props: any) => {
  const { component } = props;

  const queryParams = useQueryParams();
  const username = _get(queryParams, [0, "u"]);
  const code = _get(queryParams, [0, "code"]);

  const dispatch = useDispatch();

  useEffect(() => {

    // check for query param w/ token
    if (code && username) {
        dispatch({
            type: AuthActionType.CompleteConsent,
            payload: {
                username: username,
                code: code,
              },
        })
    } else {
        navigate('/login');
    }

  }, []);

  return component;
};

export default ProvideConsentRoute;
