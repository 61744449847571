import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Theme, Card, CardContent, Typography, } from '@material-ui/core';

import Page from '../../components/page';
import YouTube from 'react-youtube';
import { ATsetupAndUseLink, ATtelehealthinstall } from '../../settings/app';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  videoPlayer: {
    maxWidth: 400,
    height: '280px',
    padding: '10px'
  },
  resourceCard: {
    margin: '15px', 
    display: 'inline-grid',
    minWidth: '450px',
    height: '340px'
  },
  videoBox: {
    display: 'inline-box',
    paddingBottom: '20px',
  },
  resourceLink: {
    padding: '20px',
    textAlign: 'center'
  },
  resourceTitle: {
    fontSize: '14px',
    paddingBottom: '10px',
    color: '#546e7a'
  }
}));

const openLink = (strLink: string) => {
  window.open( strLink, "_blank"); 
}

const TutorialsDefault = (props: any) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Tutorials"
    >

    <Card className={classes.resourceCard}>
      <CardContent>
        <div className={classes.resourceTitle}>
          Getting Started
        </div>
        <div className={classes.resourceLink}>
        <button
            title="support"
            className="k-button k-primary"
            onClick={() => openLink(ATsetupAndUseLink)}
          >
          Setup and Use Instructions
        </button>
        </div>
        
      </CardContent>
    </Card>

    <Card className={classes.resourceCard}>
      <CardContent>        
        <div className={classes.resourceTitle}>
          In-Person Sessions
        </div>
        <div className={classes.videoBox}>
            <YouTube 
            id="in-person-sessions"
            videoId="RYepr-84w0Y"
            className={classes.videoPlayer}/>
        </div>
      </CardContent>  
    </Card>

    <Card className={classes.resourceCard}>
      <CardContent>
        <div className={classes.resourceTitle}>
          Telehealth Demonstration
        </div>
        <div className={classes.videoBox}>
            <YouTube 
            id="video-telehealth"
            videoId="gVM8hleal98"
            className={classes.videoPlayer}/>
        </div>
      </CardContent>  
    </Card>

    <Card className={classes.resourceCard}>
      <CardContent>
        <div className={classes.resourceTitle}>
          Create a Custom Program
        </div>
        <div className={classes.videoBox}>
            <YouTube 
            id="create-a-program"
            videoId="r0yVX_qnuGc"
            className={classes.videoPlayer}/>
        </div>

      </CardContent>  
    </Card>

    </Page>
  );
}

export default TutorialsDefault;
