import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navigate, useQueryParams } from "hookrouter";
import { get as _get } from "lodash";
import { AuthActionType } from "./store/auth/auth-action-types";

const AutoLoginRoute = (props: any) => {
  const { component } = props;

  const queryParams = useQueryParams();
  const token = _get(queryParams, [0, "token"]);
  const token2 = "CfDJ8GQUSH0JUjBInOVtMHQ4urVuF_-oxNwGxQc96os6GQw7u5OE1-maXyG9LdM_c41D7Dh9NRQZp7ytiqK0Jeu3vRldW15_TR4XxGIIftgxTScFRS7O1yTqdduf4W9DOxTEsb2BRLJXy0wl0IG1PTQ4VrNwKQoMm9juuV-bc1qvbmM8AhlebRbsNkU-6a1npwK_-IGjt49WUuhzCdsgNc7tCzL6K3sro8lxsp2yJ4P2Of5OIMfzTDcdDe5K5iM47_D6u-WZq-bn_p5C0U66lMv-dJObut75Z-mKqvjpvMrJz_inSIIGzcSsxA4YIarpClyIIdXx-ugqCIiz5FUR7h5gS6qjT_esjGD6rq8U9Yyuz4q3dDXGm3-RqVUGgvzfIqve9pQiyVaB2ZNLofPL6JzXmCFgLd2_YAORS8sb2neoHU-_LO956qGSEIX21RT0jqlAV8PFMIzmXa2z-e7mq2Somtdo5xaq9DjuM7PWU7SvqmUaBo0OgaS0-pb482ffBTOTEjlCSMElzuNrp1vf_dqszOeDd24OfJ6z1AlYoPeO-h0n6VYATpvlBmEyUs9RsBFBIfEfB3ApIdv2Ekq252nqk0WbtS80HDVKozcBdxmuHhKiNR__C6gncxrmvU_Im_dtakERsMr17h18E7xOLUvpMLQ";

  const dispatch = useDispatch();

  useEffect(() => {

    // check for query param w/ token
    if (token) {
        dispatch({
            type: AuthActionType.AutoLogin,
            payload: token
        })
    } else {
        navigate('/login');
    }

  }, []);

  return component;
};

export default AutoLoginRoute;
