import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getAtError } from '../../settings/error';

import { DashboardActionType } from './dashboard-action-types';
import { AuthActionType } from '../auth/auth-action-types';

import {
  atGetReportUsage, atGetReportTimeline
} from '../../services/at-app-dashboard-api';

function* getReportUsage(action: any) {
  try {
    const data = yield call(atGetReportUsage, action.payload);
    for (let i = 0; i < data.data.length; i++) {
      data.data[i].date = new Date(data.data[i].date)
    }
    yield put({ type: DashboardActionType.GetReportUsageSuccess, payload: data });
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: DashboardActionType.GetReportUsageFailure, message: error });
    }
  }
}

function* getReportTimeline(action: any) {
  try {
    const data = yield call(atGetReportTimeline, action.payload);
    yield put({ type: DashboardActionType.GetReportTimelineSuccess, payload: data });
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: DashboardActionType.GetReportTimelineFailure, message: error });
    }
  }
}
const dashboardSagas = [
  takeLatest(DashboardActionType.GetReportTimeline, getReportTimeline),
  takeLatest(DashboardActionType.GetReportUsage, getReportUsage),
];

export {
  dashboardSagas,
}
