import { Button, colors, makeStyles } from '@material-ui/core';
import { Theme } from 'grid-styled';
import React, { useEffect, useRef, useState } from 'react';

import QRCode from "qrcode.react";
import { useReactToPrint } from 'react-to-print';
import DialogContainer from '../../../../components/modals/dialog-container';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
    },
    qr: {
        margin: "auto",
    },
    printButton: {
        color: colors.common.white,
        backgroundColor: colors.green[400],
        '&:hover': {
        backgroundColor: colors.green[900]
        },
    },
    bottom: {
        display: "flex",
        float: "right",
    },
    p: {
        margin: "auto"
      },
    img: {
        paddingTop: 20,
        margin: "auto"
    }
  }));

const UserQRCode = (props: any) => {
    const {
            id,
            qrCodeString,
            size,
            userfullname,
            subType,
            handleClose,
            open
    } = props;

    const [ openModal, setOpenModal ] = useState(open);

    const classes = useStyles();

    const myRef = useRef(null);

    const staffOrPatientString = subType === 'patients' ? 'Client' : 'Staff';

    const closeIt = () => {
        setOpenModal( false);
        handleClose();
      };

    useEffect(() => {
        if (!openModal) {
            handleClose()
        }

        return () => {};
      }, [handleClose, openModal]);

    const printQR = 
        useReactToPrint( {
            content: () => myRef.current,
        })

    const qrHeader = () => {
        if (subType === 'patients') {
            return 'AT Patient';
        }
        return 'AT Staff';
    }

    return (
        <div>
            <DialogContainer
                title={'Sign In Badge'} 
                fullWidth={true}
                maxWidth={'sm'}
                open={openModal}
                handleClose={closeIt}
            >
                <div className={classes.root} ref={myRef}>
                    {/* <img className={classes.img}src="./images/headerLogo.png" alt="Augment Therapy" /> */}
                    <div className={classes.img}>AT {staffOrPatientString}</div>
                    <QRCode
                        id={id}
                        value={qrCodeString}
                        size={size}
                        level={"H"}
                        includeMargin={true}
                        className= {classes.qr}
                        
                    />
                    <p className={classes.p} >{userfullname}</p>
                </div>
                <div className={classes.bottom} >
                    <Button onClick={printQR} className={classes.printButton} aria-label="Print" component="span">
                        Print Badge
                    </Button>
                </div>
            </DialogContainer>
        </div>
    );
}

export default UserQRCode;
