/* eslint-disable no-undef */
import React, { HTMLAttributes } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

export interface IPageProps
  extends HTMLAttributes<HTMLDivElement> {
  title?: string,
  children?: any
}
export const Page : React.FC<IPageProps> = ({ title, children, ...rest }) => {

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
