import axios from 'axios';
import URLSearchParams from 'url-search-params';
import { APIURL_PRODUCTION, APIURL_TESTING, RUN_MODE } from '../settings/app';

// PRODUCTION
export var APIURL: string;
if (RUN_MODE==="production") {
  APIURL = APIURL_PRODUCTION;
} else 
{
  APIURL = APIURL_TESTING;
}

export const client = (cotentType: string = 'application/json;charset=UTF-8') => {
  const token = sessionStorage.getItem('at_api_access_token');
  const token_type = sessionStorage.getItem('at_api_token_type');

  const defaultOptions = {
    headers: {
      'Authorization': token ? `${token_type} ${token}` : '',
      'Content-Type': cotentType,
    },
  };

  return {
    get: (url: string, options: any = {}) => axios.get(url, { ...defaultOptions, ...options }),
    post: (url: string, data: any, options: any = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url: string, data: any, options: any = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (url: string, options: any = {}) => axios.delete(url, { ...defaultOptions, ...options }),
  };
};

export const atAPIOptions = (options = {}, token: string = '') => {
    const defaultOptions = {
        headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json;charset=UTF-8',
        },
        cache: 'no-cache',
    };

    return { ...defaultOptions, options };
}

export const atLogin = (username: string, password: string) => {
  let body = new URLSearchParams();
  body.set('grant_type', 'password');
  body.set('username', username);
  body.set('password', password);
  body.set('scope', 'offline_access');
  body.set('client_id', 'at');
  body.set('client_secret', '8A2DEAFC-7836-4104-A830-70EDE733D152');

  const request = client();
  return request.get(
      `${APIURL}/api/report/usage` //api/userinfo
  );

  // return axios.post(`${APIURL}/connect/token`, body.toString());
};

export const atReportUsage = () => {
  const request = client();
  return request.get(
      `${APIURL}/api/report/usage` //api/userinfo
  );
};

export const atRefreshLogin = () => {
  const refresh_token = localStorage.getItem('refresh_token');
  const data = {
    refresh_token: refresh_token
  };

  const request = client();
  return request.post(
    `${APIURL}/user/refreshLogin`,
    data,
  );
};


export const callPagApi = async (token: string = '', getToken: any, api: any, variables: any = null, signal: any) => {
	try {

		if (!token)
			token = await getToken();

		const params: any = {
				context: {
					headers: {
						Authorization: `Bearer ${token}`
          },
          fetchOptions: {
            signal,
          },
				}
			};

		if (variables) {
			params['variables'] = variables;
		}

		api(params);

		return token;

	} catch (error) {
		console.error(error);
	}
};
