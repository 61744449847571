import { combineReducers, createStore, applyMiddleware } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createSagaMiddleware from 'redux-saga';

import { AuthActionType } from './auth/auth-action-types';

import { IAuthState } from './auth/auth-redux.model';
import { IUserState } from './user/user-redux.model';
import { IDashboardState } from './dashboard/dashboard-redux.model';
import { IFacilitiesState } from './facilities/facilities-redux.model';

import authReducer, { initialAuthState } from './auth/auth.reducer';
import userReducer, { initialUserState } from './user/user.reducer';
import dashboardReducer, { initialDashboardState } from './dashboard/dashboard.reducer';
import facilitiesReducer, { initialFacilitiesState } from './facilities/facilities.reducer';

import rootSaga from './index.saga';

export interface IAppError {
  error: string;
  errorDescription: string;
}

const persistConfig = {
  key: 'atAppStore',
  storage: storage,
  // blacklist: ['auth']
}

// const authPersistConfig = {
//   key: 'auth',
//   storage: storage,
//   blacklist: ['somethingTemporary']
// }

// const rootReducer = combineReducers({
//   auth: persistReducer(authPersistConfig, authReducer),
//   other: otherReducer,
// })

export interface IAppState {
  auth: IAuthState;
  user: IUserState;
  dashboard: IDashboardState;
  facilities: IFacilitiesState;
};

const initialState = {
  auth: initialAuthState,
  user:initialUserState,
  dashboard: initialDashboardState,
  facilities: initialFacilitiesState,
};

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  facilities: facilitiesReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'ResetStore') {
    sessionStorage.removeItem('at_api_token_type');
    sessionStorage.removeItem('at_api_access_token');
    sessionStorage.removeItem('at_api_expires_in');
    sessionStorage.removeItem('at_api_refresh_token');
    state = initialState;
  }

  return appReducer(state, action);
}

//const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

// const persistor = persistStore(store);

export {
  initialState,
  rootReducer,
  store
}
