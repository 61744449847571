import URLSearchParams from 'url-search-params';
import {
  APIURL,
  client,
  atAPIOptions,
} from './at-app-api';


export const atGetReportUsage = (payload: any) => {
  let url = `${APIURL}/api/report/usage`;
  const params: any = {};
  const request = client();

  if (payload.uid) {
    url = `${APIURL}/api/report/user/usage`;
    params['uid'] = payload.uid;
  } else {
    params['facilityType'] = payload.facilityType;
  }

  return request.get(
    url,
    {
      params: params
    }
  );
};


export const atGetReportTimeline = (payload: any) => {
  let url = `${APIURL}/api/report/timeline`;
  const params: any = {};
  const request = client();

  if (payload.uid) {
    //url = `${APIURL}/api/report/user/timeline`;
    params['uid'] = payload.uid;
  } 
  if (payload.type) {
    params['type'] = payload.type;
  } 
  var yyyy;
  var mm;
  var dd;
  if (payload.startDate) {
    yyyy = payload.startDate.getFullYear();
    mm = payload.startDate.getMonth() + 1; // getMonth() is zero-based
    mm = (mm>9 ? '' : '0') + mm;
    dd = payload.startDate.getDate();
    dd = (dd>9 ? '' : '0') + dd;
    params['start'] = yyyy+'-'+mm+'-'+dd;
  } 
  if (payload.endDate) {
    yyyy = payload.endDate.getFullYear();
    mm = payload.endDate.getMonth() + 1; // getMonth() is zero-based
    mm = (mm>9 ? '' : '0') + mm;
    dd = payload.endDate.getDate();
    dd = (dd>9 ? '' : '0') + dd;
    params['end'] = yyyy+'-'+mm+'-'+dd;
  } 

  return request.get(
    url,
    {
      params: params
    }
  );
};