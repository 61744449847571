import React, { useState, useEffect, useMemo, useCallback, useContext, Suspense} from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { usePath, useRoutes, useRedirect } from 'hookrouter';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Theme } from '@material-ui/core';

import {
  get as _get
} from 'lodash';

import NavBar from './nav-bar';
import TopBar from './top-bar';
import { UserRoles } from '../../settings/user-roles';


const useStyles = makeStyles((theme : Theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 78,
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  withNavbar: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 170
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

const Dashboard = (props: any) => {
  const {
    children,
  } = props;
  const classes = useStyles();
  const userProfile = useSelector(state => _get(state, ['user', 'profile']) || {}, shallowEqual);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const userRole = _get(userProfile, ['role']) || '';
  const visibleNavbar = [UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN].indexOf(userRole) >= 0;

  return (
    <>
      <TopBar
        userProfile={userProfile}
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}/>
      {
        visibleNavbar && (
          <NavBar
            onMobileClose={() => setOpenNavBarMobile(false)}
            openMobile={openNavBarMobile}
            userProfile={userProfile}
          />
        )
      }

      <div className={clsx(classes.container, visibleNavbar ? classes.withNavbar : null)}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {children}
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
