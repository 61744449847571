import React, { lazy } from 'react';
import {
	parseInt as _parseInt,
} from 'lodash';

import AuthLayout from './layouts/auth';
import DashboardLayout from './layouts/dashboard';

import ProtectedRoute from './protected-route';

import {
  Navigations
} from './settings/nav-config';
import ResourcesDefault from './pages/resources';
import TutorialsDefault from './pages/tutorials';
import TelehealthDefault from './pages/telehealth';
import AutoLoginContainer from './pages/auth/autologin';
import AutoLoginRoute from './autologin-route';
import ExportDataDefault from './pages/exportdata';
import ProvideConsentContainer from './pages/auth/provide-consent';
import ProvideConsentRoute from './provide-consent-route';
import ConsentSuccessContainer from './pages/auth/provide-consent/consent-success';
import ConsentFailureContainer from './pages/auth/provide-consent/consent-failure';

const LoginContainer = React.lazy(() => import('./pages/auth/login'));
const AgreementContainer = React.lazy(() => import('./pages/user/agreements'));
const ResetPasswordContainer = React.lazy(() => import('./pages/auth/reset-password'));

const DashboardDefault = React.lazy(() => import('./pages/dashboard'));
const FacilitiesGridContainer = React.lazy(() => import('./pages/facilities/containers/facilities-grid-container'));
const SessionsGridContainer = React.lazy(() => import('./pages/facilities/containers/sessions-grid-container'));
const FacilityEditContainer = React.lazy(() => import('./pages/facilities/containers/facility-edit-container'));
const UserEditContainer = React.lazy(() => import('./pages/facilities/containers/user-edit-container'));
const TherapistViewContainer = React.lazy(() => import('./pages/facilities/containers/therapist-view-container'));
const PatientViewContainer = React.lazy(() => import('./pages/facilities/containers/patient-view-container'));
const SessionViewContainer = React.lazy(() => import('./pages/facilities/containers/session-view-container'));

const routes = {
	'/': () => <ProtectedRoute
    component={
      <DashboardLayout> <DashboardDefault/> </DashboardLayout>
    }/>,
  [Navigations.dashboard.root]: () => <ProtectedRoute
    component={
      <DashboardLayout> <DashboardDefault/> </DashboardLayout>
    }/>,
  [`${Navigations.dashboard.root}/${Navigations.dashboard.analytics}`]: () => <ProtectedRoute
    component={
      <DashboardLayout> <DashboardDefault/> </DashboardLayout>
    }/>,

    [`${Navigations.auth.autologin}`]: () => <AutoLoginRoute
    component={
      <AuthLayout> <AutoLoginContainer/> </AuthLayout>
    }/>,

    [`${Navigations.auth.completeConsent}`]: () => <ProvideConsentRoute
    component={
      <AuthLayout> <ProvideConsentContainer/> </AuthLayout>
    }/>,
    [`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.consentSuccess}`]: () => <ProvideConsentRoute
    component={
      <AuthLayout> <ConsentSuccessContainer/> </AuthLayout>
    }/>,
    [`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.consentFailure}`]: () => <ProvideConsentRoute
    component={
      <AuthLayout> <ConsentFailureContainer/> </AuthLayout>
    }/>,

  [Navigations.auth.login]: () => <AuthLayout> <LoginContainer/> </AuthLayout>,

  [Navigations.auth.agreement]: () => <AuthLayout> <AgreementContainer/> </AuthLayout>,

  [`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.resetPasswordLink}`]: () =>
    <AuthLayout>
      <ResetPasswordContainer
        resetPasswordType={Navigations.auth.accountSecurity.resetPasswordLink}
      />
    </AuthLayout>,

  [`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.resetPassword}`]: () =>
    <AuthLayout>
      <ResetPasswordContainer
        resetPasswordType={Navigations.auth.accountSecurity.resetPassword}
      />
    </AuthLayout>,

  [`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.completeSetup}`]: () =>
  <AuthLayout>
    <ResetPasswordContainer
      resetPasswordType={Navigations.auth.accountSecurity.completeSetup}
    />
  </AuthLayout>,

  [`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.updatePassword}`]: () =>
    <AuthLayout>
      <ResetPasswordContainer
        resetPasswordType={Navigations.auth.accountSecurity.updatePassword}
      />
    </AuthLayout>,

  [`${Navigations.facilities.root}/:subType`]: ({subType}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <FacilitiesGridContainer type="facilities" subType={subType}/>
        </DashboardLayout>
      }/>,

  [`${Navigations.facilities.root}/:subType/new`]: ({subType}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <FacilityEditContainer type="facilities" subType={subType} editType="new"/>
        </DashboardLayout>
      }/>,

  [`${Navigations.facilities.root}/:subType/edit`]: ({subType}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <FacilityEditContainer type="facilities" subType={subType} editType="edit"/>
        </DashboardLayout>
      }/>,

  [`${Navigations.facilities.root}/:subType/:uid`]: ({subType, uid}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <FacilitiesGridContainer type="facilities" subType={subType} uid={uid}/>
        </DashboardLayout>
      }/>,

  [`${Navigations.users.root}/:subType`]: ({subType}: any) =>
      <ProtectedRoute
        component={
          <DashboardLayout>
            <FacilitiesGridContainer type="users" subType={subType}/>
          </DashboardLayout>
        }/>,

  [`${Navigations.users.root}/:subType/edit`]: ({subType}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <UserEditContainer type="users" subType={subType} editType="edit"/>
        </DashboardLayout>
      }/>,

  [`${Navigations.users.root}/:subType/new`]: ({subType}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <UserEditContainer type="users" subType={subType} editType="new"/>
        </DashboardLayout>
      }/>,

  [`${Navigations.users.root}/:subType/:uid`]: ({subType, uid}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          {
            subType === Navigations.users.therapists ? (
              <TherapistViewContainer type="users" subType={subType} uid={uid}/>
            ) : (
              <PatientViewContainer type="users" subType={subType} uid={uid}/>
            )
          }
        </DashboardLayout>
      }/>,

  [`${Navigations.sessions.root}/:subType`]: ({subType}: any) =>
    <ProtectedRoute
      component={
        <DashboardLayout>
          <SessionsGridContainer type="sessions" subType={subType}/>
        </DashboardLayout>
      }/>,
  [`${Navigations.session.root}/:uid`]: ({uid}: any) =>
    <ProtectedRoute
      component={
        <AuthLayout>
          <SessionViewContainer uid="uid"/>
        </AuthLayout>
      }/>,
  [`${Navigations.resources.root}`]: () =>
  <ProtectedRoute
    component={
      <DashboardLayout> <ResourcesDefault/> </DashboardLayout>
    }/>,
  [`${Navigations.tutorials.root}`]: () =>
  <ProtectedRoute
    component={
      <DashboardLayout> <TutorialsDefault/> </DashboardLayout>
    }/>,
    [`${Navigations.telehealth.root}`]: () =>
  <ProtectedRoute
    component={
      <DashboardLayout> <TelehealthDefault/> </DashboardLayout>
    }/>,
  [`${Navigations.exportdata.root}`]: () =>
    <ProtectedRoute
      component={
        <DashboardLayout> <ExportDataDefault/> </DashboardLayout>
    }/>,
}

export default routes;
