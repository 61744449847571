import React, { useState } from 'react';
import { navigate, usePath } from 'hookrouter';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  get as _get,
  startsWith as _startsWith,
} from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CSSObject } from 'styled-components';

const useStyles = makeStyles((theme: CSSObject) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 12
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    color: 'rgb(88,106,135)'
  },
  buttonLeaf: {
    fontWeight: 500,
    '&.depth-0': {
      fontWeight: 500
    }
  },
  icon: {
    //  color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: 12, //theme.spacing(1)
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    fontFamily: 'Open Sans',
    paddingBottom: 24,
    fontWeight: 800,
    color: 'rgb(88,106,135)'
  },
  active: {
    color: 'black', //theme.palette.primary.main,
    background: 'rgba(0,0,0,0.05)',
    fontWeight: 600,
    '& $icon': {
      color: 'black', //theme.palette.primary.main
    }
  }
}));

const NavItem = (props: any) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    onClick,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const path = usePath();
  const active = _startsWith(path, href);

  const handleToggle = () => {
    if (!!children)
      setOpen(!open);
    if (href) {
      navigate(href);
    }
  };

  let paddingLeft = 12;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  return (
    <ListItem
      {...rest}
      className={clsx(classes.item, className)}
      disableGutters
      key={title}
    >
      <Button
        className={clsx({
          [classes.button]: true,
          [classes.active]: active,
          className: true,
          [classes.itemLeaf]: !children,
          [`depth-${depth}`]: !children
        })}
        onClick={(e) => {
          if (onClick)
           onClick(e);
          handleToggle()
        }}
        style={style}
      >
        {Icon && <Icon className={classes.icon} />}
        {title}
        {!children && Label && (
          <span className={classes.label}>
            <Label />
          </span>
        )}

        {
          children && (
            <>
              {
                open ? (
                  <ExpandLessIcon
                    className={classes.expandIcon}
                    color="inherit"
                  />
                ) : (
                  <ExpandMoreIcon
                    className={classes.expandIcon}
                    color="inherit"
                  />
                )
              }
            </>
          )
        }
      </Button>
      {
        children && (
          <Collapse in={open}>{children}</Collapse>
        )
      }
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
