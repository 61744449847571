import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	colors,
	Theme
  } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
	  position: 'relative',
	  display: 'flex',
	  justifyContent: 'center',
	  alignItems: 'center',
	  minHeight: 300,
	  height: '100%',
	}
  }));

const NotFound = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Typography variant="h4">
				Page not found
			</Typography>
		</div>
	);
};

export default NotFound;
